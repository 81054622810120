import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import { of } from 'rxjs';
import { SetTitle, SetTags } from '../../store';
import { StringUtility } from '@teamfoster/sdk/text-utility';
import { getActivePortal, getActivePortalLoaded } from '../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

export const PortalMetaGuard: CanActivateFn = route => {
  const store = inject(Store);
  const lang = inject(LanguageService);
  const dict = new FromDictionaryPipe(lang);
  return store
    .select(getActivePortalLoaded)
    .pipe(
      withLatestFrom(store.select(getActivePortal)),
      tap(([loaded, portal]) => {
        if (!loaded) {
          return;
        }
        store.dispatch(SetTitle({ title: `${portal?.metaData.title}` }));
        store.dispatch(
          SetTags({
            properties: {
              'description': StringUtility.Truncate(StringUtility.StripHTML(portal!.metaData.description), 157, '...'),
              'og:image': portal?.metaData.imageUrl ? `https:${portal?.metaData.imageUrl}` : '',
              'og:title': `${portal?.metaData.title}`,
              'og:description': StringUtility.Truncate(StringUtility.StripHTML(portal!.metaData.description), 157, '...'),
            },
          })
        );
      }),
      filter(([loaded]) => loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
